import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Row, Col, Navbar, Media, Button, Modal, Form, InputGroup, FormControl, Accordion, Card } from 'react-bootstrap'
import SEO from "../components/seo"
import Layout from '../components/layout'
import Container from 'react-bootstrap/Container'
import Footer from '../components/footer'
import { bdstatistics, apiURL, mqStatistics } from '../components/census'
import '../components/market.css'

const Order = ({ siteTitle }) => {
  const [show, setShow] = useState(false);
  const [ indexType, setIndexType ] = useState(1)
  const [ billType, setBillType ] = useState(1)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // let [ detailData, setDataDetail ] = useState()
  // const [ isCheck, setCheck ] = useState(false)
  // const [ price, setPrice ] = useState(null)
  // const [ flag, setFlag ] = useState(null)
  //服务协议
  // const onChange = (e) => {
  //   let checked = e.target.checked
  //   checked ? setCheck(checked) : setCheck(false)
  // }
  // //获取参数
  // const getQueryString = (name) => { 
  //   var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); 
  //   var r = window.location.search.substr(1).match(reg); 
  //   if (r != null) return unescape(r[2]); 
  //   return null; 
  // } 

  // let type = getQueryString('type')
  // let id = getQueryString('id')
  // let versionNum = getQueryString('versionNum')

  // useEffect(()=>{
  //   //查询详情
  //   let qurey = {"where":["id=?",id ],"preloads":["Versions"]}
  //   let querystr = encodeURIComponent(JSON.stringify(qurey))
  //   api({ name: 'cloud/application/filter' })
  //     .fetch('?query='+querystr)
  //     .then(({json}) => {
  //       // console.log(json)
  //       setDataDetail(json)
  //       setPrice(json[0].price)
  //     })

  // },[])

  // const changePay =(payType) => {
  //   // setFlag(payType)
  // }

  // //保存订单
  // const paySuccess = () =>{
    
  //   api({ name: 'cloud/order' })
  //     .fetch( '?appType='+type, {
  //       method: 'POST',
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization":"LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQ0lqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FnOEFNSUlDQ2dLQ0FnRUFyaUhIZDNLa0ZXRVRRaXJJWVZ5Rwp6dkNrbGdkSCtoQVFoRVJGRy9abytTcmJSVlV1clJlb2hDNWV6Sis1SFA0c21leGo2Y1djd0cxSHg5ZC9xcUdxClc1aFNjTXhvNTVyUnBDSjNEN1VsYUdNYkhlVDR6L1YvYkVGS0ZWTXlzTTdlZ2NMNEtla0RZbXF5aUV5RzA5aDMKcENxNGhQZ0F1NytlcEFibFJxZDZwWmFQYW9CTVN0M1NEVlhpcWRXMEpteWZmaEhnaWo2QkhEd0xPZmJLN2J2RAplWkN5TEI1anZVOW5nWC9LR2pmOFUvU25XempTYWp3SmxROFliYUR1bTNIeGJqYzI1aEF0VFJwTERQRitqSmJjCmJoM3pmQThaWjEyK3dWbEdwOXRPU0ZSdXJ5bnlGNnI3bGlBc2Rha25hWHRLcWY2YUttc1JqS1ZNZFVINjMvY1AKNkFSYVBmUGN0NE4weGRIU0pkbm1NTkZhTldKLzMxbzNyMkxrTVM2TlpkRjVpNHJVUUltdGFKRzgrQkRGckM2aQpwR0hlSG14KzhXdS9PSWwvdG9qUUVXakwrK1h0b2JqTGh4c0xHa0ZuUzExZnhXdkp6TzVDR2xyeWdTVExMR2ZiClBVZ283QTNhSXVpbzFZaE0xVkIyTWRDbmR0TThlMmhOZHdwUEFhUndaM1RZR2xUcHA2b0I2bG91QUg4c1BGZUsKSVJ1amliYmluM2pZa0l1eVlRZEp5aWxJaWFEbHZlczhiOElRWmYrTzcxd2lsRVNMOUxHeHoxd1grSGhnYVNzaApzK1VLQXFmeCtzNW4xanU0dE0xRXJiQUxVcG5GTlJQZVVlNnZVSkFMOUp1b0VWZkFnV0xVcldEcldVeERRSDRnCjNBbzZWNkNtSityZGI0dXVMRkdxU3NzQ0F3RUFBUT09Ci0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQo="
  //       },
  //       body: JSON.stringify([{price: price, appId: id, versionNum: versionNum}])
  //     })
  //     .then(res=>{
  //       if(res.status == 200) {
  //         message.info('购买成功')
  //         const {json} = res
  //         upOrder(json.id)
  //         app.go('/app/market/orders')
  //       }
  //     })
  // }
  // //更新订单
  // const upOrder = (id) => {
  //   api({ name: 'cloud/order/'+id })
  //     .fetch('',{
  //       method: 'PUT',
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization":"LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQ0lqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FnOEFNSUlDQ2dLQ0FnRUFyaUhIZDNLa0ZXRVRRaXJJWVZ5Rwp6dkNrbGdkSCtoQVFoRVJGRy9abytTcmJSVlV1clJlb2hDNWV6Sis1SFA0c21leGo2Y1djd0cxSHg5ZC9xcUdxClc1aFNjTXhvNTVyUnBDSjNEN1VsYUdNYkhlVDR6L1YvYkVGS0ZWTXlzTTdlZ2NMNEtla0RZbXF5aUV5RzA5aDMKcENxNGhQZ0F1NytlcEFibFJxZDZwWmFQYW9CTVN0M1NEVlhpcWRXMEpteWZmaEhnaWo2QkhEd0xPZmJLN2J2RAplWkN5TEI1anZVOW5nWC9LR2pmOFUvU25XempTYWp3SmxROFliYUR1bTNIeGJqYzI1aEF0VFJwTERQRitqSmJjCmJoM3pmQThaWjEyK3dWbEdwOXRPU0ZSdXJ5bnlGNnI3bGlBc2Rha25hWHRLcWY2YUttc1JqS1ZNZFVINjMvY1AKNkFSYVBmUGN0NE4weGRIU0pkbm1NTkZhTldKLzMxbzNyMkxrTVM2TlpkRjVpNHJVUUltdGFKRzgrQkRGckM2aQpwR0hlSG14KzhXdS9PSWwvdG9qUUVXakwrK1h0b2JqTGh4c0xHa0ZuUzExZnhXdkp6TzVDR2xyeWdTVExMR2ZiClBVZ283QTNhSXVpbzFZaE0xVkIyTWRDbmR0TThlMmhOZHdwUEFhUndaM1RZR2xUcHA2b0I2bG91QUg4c1BGZUsKSVJ1amliYmluM2pZa0l1eVlRZEp5aWxJaWFEbHZlczhiOElRWmYrTzcxd2lsRVNMOUxHeHoxd1grSGhnYVNzaApzK1VLQXFmeCtzNW4xanU0dE0xRXJiQUxVcG5GTlJQZVVlNnZVSkFMOUp1b0VWZkFnV0xVcldEcldVeERRSDRnCjNBbzZWNkNtSityZGI0dXVMRkdxU3NzQ0F3RUFBUT09Ci0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQo="
  //       },
  //       body: JSON.stringify({ "tradeNumber": "2019-06-20 17：17" })
  //     })
  //     .then(({json})=>{
  //       console.log(json)
  //     })
  // }

  const data = [
    {
      url: require('./../images/order3.png'),
      title: '套餐信息',
      desc: '初创团队'
    },
    {
      url: require('./../images/order4.png'),
      title: '开发者信息',
      // desc: '航天科技控股集团股份有限公司北京分公司'
      desc: '时空公司'
    },
    {
      url: require('./../images/order5.png'),
      title: '购买用户信息',
      desc: '张张'
    }
  ]

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
  }, [])

  return (
    <div className="home-bg" style={{ background: `url('')` }}>
      <div className="order-box">
        <Layout>
          <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
        </Layout>
        <Navbar collapseOnSelect expand="lg" variant="dark" className="header-nav">
          <Navbar.Brand>
            <Link className="nav-title">
              {/* <img alt="" src={require('./../images/logo.png')} width="21" height="24" className="d-inline-block align-top" />{' '}
              {siteTitle}
              <span>AIRIOT 物联网平台 </span> */}
              <span>订单确认</span>
            </Link>
          </Navbar.Brand>
        </Navbar>
        <Container className="content-box">
          <div className="buy-btn con-btn">
            <img src={require('./../images/mark1.png')} className="order-img" style={{ marginRight: '.5rem' }} alt=""/>
            购买的套餐不支持退换，请确认相关信息后提交订单。
          </div> 
          <div className="buy-btn order-message">
            <img src={require('./../images/order1.png')} className="order-img" alt=""/>
            订单信息</div> 
          <Row className="order-row-box">
            {
              data.map(item => {
                return (
                  <Col>
                    <Media className="order-media">
                      {/* <img
                        width={96}
                        height={96}
                        className="mr-3"
                        src={item.url}
                        alt="Generic placeholder"
                      /> */}
                      <span className="mr-3 order-media-img" style={{ width: '6rem', height: '6rem' }}></span>
                      <Media.Body>
                        <div className="media-order-title">{item.title}</div>
                        <div className="media-order-desc">{item.desc}</div>
                      </Media.Body>
                    </Media>
                  </Col>
                )
              })
            }
          </Row>
          <div className="meal-price-box">
            <span className="meal-price">套餐价格：</span>
            <span className="meal-price-message">¥947634.00</span>
          </div>
          <div className="buy-btn order-message" style={{ width: '100%' }}>
            <img src={require('./../images/order1.png')} className="order-img" alt=""/>
            发票信息<span className="order-desc">开企业发票需填写纳税人识别号。</span></div> 
          <Button className="add-message" onClick={handleShow}>填写发票信息</Button>
          <div className="buy-btn order-message">
            <img src={require('./../images/order2.png')} className="order-img order-last-img" alt=""/>
            支付方式</div> 
          <Row >
            <Col>
              <Media className="pay-way">
                <img
                  width={64}
                  height={64}
                  className="mr-3"
                  src={require('./../images/weixin.png')}
                  alt="Generic placeholder"
                />
                <Media.Body className="way-title">微信支付</Media.Body>
              </Media>
            </Col>
            <Col>
              <Media className="pay-way">
                <img
                  width={64}
                  height={64}
                  className="mr-3"
                  src={require('./../images/zhifubao.png')}
                  alt=" placeholder"
                />
                <Media.Body className="way-title">支付宝支付</Media.Body>
              </Media>
            </Col>
            <Col></Col>
          </Row> 
        </Container>
        <Footer style={{ marginTop: '7.5rem' }}></Footer>
      </div>
      <Modal show={show} onHide={handleClose} className="bill-model">
        <Modal.Header closeButton>
          <Modal.Title>发票信息</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <InputGroup className="mb-3">
            <FormControl className="search" placeholder="输入驱动名称" />
            <img className="search-img" src={require('./../images/search.png')} alt=""/>
          </InputGroup> */}
          <div>
            <Button className={billType === 1 ? 'bill-type bill-type-active' : 'bill-type'} onClick={() => {setBillType(1)}}>电子普通发票</Button>
            <Button className={billType === 2 ? 'bill-type bill-type-active' : 'bill-type'} onClick={() => {setBillType(2)}}>增值税发票</Button>
          </div>
          <div className="bill-message">电子发票具有与纸质发票同等的法律效力，若需要纸质发票，提交信息后，需等7-10个工作日。</div>
          <Form>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={3}>发票抬头类型</Form.Label>
              <Col sm={8}>
                <Button className={indexType === 1 ? 'bill-rise bill-rise-active' : 'bill-rise'} onClick={() => { setIndexType(1) }}>个人</Button>
                <Button className={indexType === 2 ? 'bill-rise bill-rise-active' : 'bill-rise'} onClick={() => { setIndexType(2) }}>企业</Button>
              </Col>
            </Form.Group>
            {
              indexType === 2 ? (
                <div>  
                  <Form.Group as={Row} controlId="formQYQC">
                    <Form.Label column sm={3}>企业名称</Form.Label>
                    <Col sm={8}>
                      <Form.Control type="name" placeholder="请填写您企业的全称" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formNSRSBH">
                    <Form.Label column sm={3}>纳税人识别号</Form.Label>
                    <Col sm={8}>
                      <Form.Control type="name" placeholder="请填写您企业的纳税人识别号" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formQTXI">
                    <Form.Label column sm={3}>其他填写信息</Form.Label>
                    <Col sm={8} className="soft-service">
                      <Accordion>
                        <Card>
                          <Card.Header style={{ padding: 0, background: '#EEEFFF' }}>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{ color: '#5756B3', textDecoration: 'none', width: '100%' }}>填写其他发票信息</Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="0">
                            <div style={{ padding: '1rem 0.5rem' }}>
                              <Form.Group as={Row} controlId="formQYDZ">
                                <Form.Label column sm={4}>企业地址</Form.Label>
                                <Col sm={8}>
                                  <Form.Control type="name" placeholder="请填写您企业地址" />
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} controlId="formQYDH">
                                <Form.Label column sm={4}>企业电话</Form.Label>
                                <Col sm={8}>
                                  <Form.Control type="name" placeholder="请填写您企业电话" />
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} controlId="formKHYH">
                                <Form.Label column sm={4}>开户银行</Form.Label>
                                <Col sm={8}>
                                  <Form.Control type="name" placeholder="请填写您企业开户银行" />
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} controlId="formYHZH">
                                <Form.Label column sm={4}>银行账户</Form.Label>
                                <Col sm={8}>
                                  <Form.Control type="name" placeholder="请填写您企业银行账户" />
                                </Col>
                              </Form.Group>
                              </div>
                          </Accordion.Collapse> 
                        </Card>
                      </Accordion>
                    </Col>
                  </Form.Group>
                </div>) 
                : (
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={3}>个人名称</Form.Label>
                <Col sm={8}>
                  <Form.Control type="name" placeholder="请填写您的姓名或者“个人”" />
                </Col>
              </Form.Group>
              )
            }
            <Form.Group as={Row} controlId="formHorizontalPassword">
              <Form.Label column sm={3}>
                发票内容
              </Form.Label>
              <Col sm={8} className="soft-service">软件服务</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={3}>联系电话</Form.Label>
              <Col sm={8}>
                <Form.Control type="name" placeholder="您或收票人的联系电话" />
                <span className="input-desc">发票开具后会以短信的形式您。</span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={3}>邮箱</Form.Label>
              <Col sm={8}>
                <Form.Control type="name" placeholder="您或收票人的邮箱地址" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalCheck">
              <Col sm={{ offset: 3 }}>
                <Form.Check.Input type="checkbox" isValid />
                <Form.Check.Label>我已阅读并同意<span className="bill-notice">发票须知</span></Form.Check.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Button type="submit" className="bill-sub">提交</Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
     </div> 
  )
}

export default Order